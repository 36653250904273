import * as React from "react"
import {graphql } from "gatsby"


import Navbar from "../components/navbar"
import Reformfooter from "../components/footer"
// import Bookaclass from "../components/bookaclass"
import Internalbanner from "../components/internalbanner"
import Contactcontent from "../components/contact-content"
import GoogleMap from "../components/googlemap"
import Homeswiper from "../homepage/homeswiper"
// import Swipage from "../components/swiper"
import Layout from "../components/layout"
import Seo from "../components/seo"
import YogaPic4 from "../images/studio/improvers-4.jpg"


const Contact = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Contact`


  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="Contact Reform Pilates" description="Get in touch with Reform Pilates Cork"/>
     <Navbar />
    <Internalbanner title="Contact" subtitle="Visit our new studio in Cork!" bannerpic={YogaPic4}/>
     <Contactcontent />
     <GoogleMap/>
     <Homeswiper />
     <Reformfooter />
    </Layout>
  )
}

export default Contact

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
        }
      }
    }
  }
`
