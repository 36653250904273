import * as React from "react"
import styled from "styled-components";
import Shape1 from "../images/shape1.svg";
import Shape2 from "../images/shape2.svg";
import Shape3 from "../images/shape3.svg";
import Shape4 from "../images/shape4.svg";
// import Shape5 from "../images/shape5.svg";
// import Shape6 from "../images/shape6.svg";
// import Shape7 from "../images/shape7.svg";
// import Shape8 from "../images/shape8.svg";
// import Shape9 from "../images/shape9.svg";
import Shape10 from "../images/shape10.svg";
import Streetview from "../images/streetview.jpg";

const Sectionaholder = styled.div`
.shapes_a{
background-image: url('${Shape2}'); 
margin-top:520px; 
margin-left:900px; 
}
.shapes_b{
	background-image: url('${Shape4}'); margin-top:444px; margin-left:816px; 
}
.shapes_c{
	background-image: url('${Shape10}'); margin-top:880px; margin-left:1820px;
}
.shapes_d{
	background-image: url('${Shape1}'); margin-top:70px; margin-left:158px;
}
.shapes_e{
	background-image: url('${Shape3}'); margin-top:165px; margin-left:1515px;
}
.streetview{
	width: 100%;
}
`;

const Contactcontent = () => {
  return (
  <Sectionaholder>
<section className="section-classes">
		<div className="shapeshifter">
<div className="shapes shapes_a"></div>
<div className="shapes shapes_b"></div>
<div className="shapes shapes_c"></div>
<div className="shapes shapes_d"></div>
<div className="shapes shapes_e"></div></div>	
<div className="container "> 
		<div className="columns is-marginless is-centered">

		<div className="column is-two-thirds"> 
				<h1 className="anote">Reform Pilates, Cork</h1>
				<h2 className="descriptionzone">Unit 1 Celtic Business Park, Monahan Road, Cork</h2>

<p className="genpee maintext">
We’ll happily answer any questions you may have and run through what’s needed for our classes. Please get in touch using the details below.
<br/><br/>
Our Studio is located at Celtic Park, Monahan Road and is ideal for anyone in Ballintemple, Blackrock and Cork city centre areas.<br/><br/>
For any yoga lovers, we’ve taken over the former <strong>Yoga Loft Studio</strong> and given it a refresh!
</p>

			<div className="columns is-marginless">
			<div className="column is-full is-paddingless">
		<img src={Streetview} class="streetview" alt="Find Reform Pilates Cork"/>
			<p className="signature"><br/>Join us in our new studio!</p>
			</div>
	      </div>


		</div>

		</div>

	</div>
</section>
    </Sectionaholder>
  );
}

export default Contactcontent